<template>
	<div>
		<v-header  class="headerTop"></v-header>
		<div  class="listWOW">
			<div class="row">
				<div class="slideInLeft">
					<div class="wow rollIn"> <img src="../../assets/images/solution/ei/ei_01.png" /> </div>
				</div>
				<div class="slideInRight">
					<div class="wow bounceInDown">	<img src="../../assets/images/solution/ei/ei_01_title.png" />	</div>
<!--					<div class="wow lightSpeedIn cursorHand  wowMargin"  @click="schoolpath()"><img src="../../assets/images/schoolonline_10.png" /></div>-->
				</div>
			</div>
			<div class="row" style="background: #5090bd;">
				<div class="slideInLeft">
					<div class="wow bounceInLeft"><img src="../../assets/images/solution/ei/ei_02.png" /></div>
				</div>
				<div class="slideInRightN">
					<div class="wow flipInX"><img src="../../assets/images/solution/ei/ei_02_title.png" /></div>
				</div>
			</div>

			<div class="row" style="background: #7aafd3;">

				<div class=" slideInRightN">
					<div class="wow bounceInLeft "><img src="../../assets/images/solution/ei/ei_03.png" style="width: 100% !important;" /></div>
				</div>

			</div>


      <div class="row" style="background: #5090bd;">
        <div class="slideInLeft">
          <div class="wow bounceInLeft"><img src="../../assets/images/solution/ei/ei_04.png" /></div>
        </div>
        <div class="slideInRightN">
          <div class="wow flipInX"><img src="../../assets/images/solution/ei/ei_04_title.png" /></div>
        </div>
      </div>


		</div>
		<v-footer></v-footer>
	</div>

</template>

<script>
	import Header from '../../components/header';
	import Hfooter from '../../components/Hfooter.vue';
	export default{
		name:'schoolonline',
		components:{
			'v-header': Header,
			'v-footer': Hfooter
		},
		methods:{
			schoolpath(){
				var schoolUrl = 'http://39.108.49.151:8088/index';
				window.open(schoolUrl, '_blank');
			}
		}
	};
</script>

<style>
</style>
